import { useParams } from "next/navigation";
import { useEffect, useMemo, useState } from "react";
import ListTeams from "~/components/lists/MainPage/ListTeams";
import ListUsers from "~/components/lists/MainPage/ListUsers";
import TeamsTable from "~/components/lists/MainPage/TeamsTable";
import UsersTable from "~/components/lists/MainPage/UsersTable";
import { useTeamAccount } from "~/providers/TeamAccountProvider";

import EditSelection from "~/components/lists/Utils/EditSelection";
import { useLogger } from "~/providers/LoggerProvider";
import {
  Enums,
  pluralize,
  readableErrorFromScreamingSnake,
} from "@openqlabs/utils";
import useListParams from "./SharedTable/hooks/useListParams";
import useDismissible from "~/hooks/useDissmissable";
import Notification from "~/components/base/Notification/Notification";
import { useLists } from "~/providers/ListsProvider";
import NoResults from "./NoResults";
import UltraLightTable from "./UltraLightTable";
import { api } from "~/server/trpc/react";
import { Skeleton } from "~/components/ui/skeleton";

const ListContactInfoDashboard = ({
  activeHeaderMenu,
  showTable,
}: {
  activeHeaderMenu: string;
  showTable: boolean;
}) => {
  const type = activeHeaderMenu === "Teams" ? "repo" : "user";
  const params = useParams() as { listId: string };
  const { listId } = params as { listId: string };
  const { lists } = useLists();

  const logger = useLogger();
  const { activeTeamAccount } = useTeamAccount();
  const { data: contactBatchCreationFailCounts } =
    api.contactBatchCreation.getContactBatchCreationErrorCounts.useQuery({
      teamAccountId: activeTeamAccount.id,
      listId: listId as string,
      type,
    });

  const [loading, setLoading] = useState<boolean>(true);

  const listIds =
    typeof listId === "string" ? [listId] : (lists.map((c) => c.id) ?? []);
  /*
  const { data: listLightModes } = api.list.listModesByIds.useQuery({
    listIds: listIds,
    teamAccountId: activeTeamAccount.id,
  });
  */

  const showEngagement = true;

  const { user: userListParams, repo: repoListParams } = useListParams();
  const {
    data: contactUsers,
    fetchNextPage: fetchNextUserPage,
    hasNextPage: hasNextUserPage,
    isLoading: isLoadingUsers,
    isFetchingNextPage: isFetchingNextUsersPage,
  } = api.listContact.getContactsByListId.useInfiniteQuery(userListParams, {
    getNextPageParam: (lastPage) => {
      const nextCursor = lastPage?.nextCursor;
      return nextCursor;
    },
  });

  const {
    data: contactRepos,
    fetchNextPage: fetchNextRepoPage,
    hasNextPage: hasNextRepoPage,
    isLoading: isLoadingRepo,
    isFetchingNextPage: isFetchingNextReposPage,
  } = api.listContact.getContactsByListId.useInfiniteQuery(repoListParams, {
    getNextPageParam: (lastPage) => {
      const nextCursor = lastPage.nextCursor;
      return nextCursor;
    },
  });

  const {
    data: ultraLightUsers,
    fetchNextPage: fetchNextUltraLightUserPage,
    hasNextPage: hasNextUltraLightUserPage,
    isLoading: isLoadingUltraLightUsers,
    isFetchingNextPage: isFetchingNextUltraLightUsersPage,
  } = api.listContact.getUltraLightContactsByListId.useInfiniteQuery(
    {
      teamAccountId: activeTeamAccount.id,
      listId: listId as string,
      type: "user",
      sorting: {
        field: "_id",
        direction: "desc",
      },
      limit: 50,
    },
    {
      getNextPageParam: (lastPage) => {
        const nextCursor = lastPage.nextCursor;
        return nextCursor;
      },
    }
  );
  const key = "contactBatchCreationErrorCount".concat(
    (contactBatchCreationFailCounts?.totalErrorCount ?? "").toString()
  );
  const { dismissed } = useDismissible(
    (listId as string) ?? activeTeamAccount.id,
    key
  );

  const { data } = api.contactBatchCreation.get.useQuery({
    teamAccountId: activeTeamAccount.id,
    listId: listId as string,
  });
  const userCount = data?.user ?? 0;
  const repoCount = data?.repo ?? 0;
  const totalNumberOfContactsWaiting = userCount + repoCount;

  const handleGetNextTeamPage = () => {
    if (hasNextRepoPage) {
      fetchNextRepoPage().catch((err) =>
        logger.error(err, "ListContactInfoDashboard.tsx1")
      );
    }
  };

  const handleGetNextUserPage = () => {
    if (hasNextUserPage) {
      fetchNextUserPage().catch((err) =>
        logger.error(err, "ListContactInfoDashboard.tsx2")
      );
    }
  };

  const handleGetNextUltraLightUserPage = () => {
    if (hasNextUltraLightUserPage) {
      fetchNextUltraLightUserPage().catch((err) =>
        logger.error(err, "ListContactInfoDashboard.tsx3")
      );
    }
  };
  const flatRepoData = useMemo(
    () => contactRepos?.pages?.flatMap((page) => page.items) ?? [],
    [contactRepos]
  );
  const flatUserData = useMemo(
    () => contactUsers?.pages?.flatMap((page) => page.items) ?? [],
    [contactUsers]
  );
  const flatUltraLightUserData = useMemo(
    () => ultraLightUsers?.pages?.flatMap((page) => page.items) ?? [],
    [ultraLightUsers]
  );

  useEffect(() => {
    if (flatRepoData && flatUserData && ultraLightUsers) {
      setLoading(false);
    }
  }, [flatRepoData, flatUserData, ultraLightUsers]);
  const hasTeams =
    (isLoadingRepo && flatRepoData?.length === 0) || flatRepoData?.length !== 0;

  const hasUsers =
    (isLoadingUsers && flatUserData?.length === 0) ||
    flatUserData?.length !== 0;

  const hasUltraLightUsers =
    (isLoadingUltraLightUsers && flatUltraLightUserData?.length === 0) ||
    flatUltraLightUserData?.length !== 0;

  if (Array.isArray(listId)) return null;
  const loadedWithLists = listIds.length > 0 && !loading;
  const loadedWithNoLists = listIds.length === 0 && !loading;

  const { totalErrorCount } = contactBatchCreationFailCounts ?? {
    totalErrorCount: 0,
  };
  const readableType = type === "repo" ? "repository" : "user";
  const currentList = lists.find((c) => c.id === listId);
  const isUltraLight =
    (listId && currentList?.mode === Enums.ListMode.ULTRALIGHT) === true;

  const errorTypes = Object.entries(
    contactBatchCreationFailCounts?.errorTypes ?? {}
  );

  const cleanupAlertHash = useMemo(
    () =>
      JSON.stringify({
        totalErrorCount,
        readableType,
        errorTypes,
      }),
    [totalErrorCount, readableType, errorTypes]
  );
  if (isLoadingUsers || isLoadingRepo || isLoadingUltraLightUsers) {
    return <Skeleton className="h-[600px] w-full" />;
  }

  return (
    <>
      {!dismissed && totalErrorCount !== 0 && activeHeaderMenu && (
        <div className="">
          <Notification
            id="cleanup-alert"
            hash={cleanupAlertHash}
            title="Cleanup Alert: Contacts Updated"
            info={false}
            className="w-full"
          >
            <div className="flex-1">
              We cleaned your imported data of {totalErrorCount}{" "}
              {pluralize(readableType, totalErrorCount)}, following entries have
              been removed:
              <ul className="list-style-disk">
                {errorTypes
                  .sort((a, b) => a[0].localeCompare(b[0]))
                  .map(([error, count]) => {
                    if (count === 0) return null;
                    return (
                      <li key={error}>
                        {readableErrorFromScreamingSnake(error)}: {count}{" "}
                        {pluralize(readableType, count)}
                        {error === "REPO_TOO_LARGE" &&
                          " (contributors were added)"}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </Notification>
        </div>
      )}
      {activeHeaderMenu === "Teams" && (
        <>
          {hasTeams && (
            <>
              {showTable ? (
                <>
                  <EditSelection />
                  <TeamsTable
                    isLoadingRepo={isLoadingRepo}
                    isFetchingNextReposPage={isFetchingNextReposPage}
                    hasNextRepoPage={hasNextRepoPage}
                    handleGetNextTeamPage={handleGetNextTeamPage}
                    repoContactMap={flatRepoData}
                  />
                </>
              ) : (
                flatRepoData && (
                  <ListTeams
                    handleGetNextTeamPage={handleGetNextTeamPage}
                    repoContactMap={flatRepoData}
                    hasNextRepoPage={hasNextRepoPage}
                    isFetchingNextReposPage={isFetchingNextReposPage}
                  />
                )
              )}
            </>
          )}
        </>
      )}

      {activeHeaderMenu === "Contributors" &&
        hasUltraLightUsers &&
        isUltraLight && (
          <div>
            <UltraLightTable
              userContactMap={flatUltraLightUserData}
              handleGetNextUserPage={handleGetNextUltraLightUserPage}
              hasNextUserPage={hasNextUltraLightUserPage}
              isFetchingNextUsersPage={isFetchingNextUltraLightUsersPage}
              isLoadingUsers={isLoadingUltraLightUsers}
            />
          </div>
        )}
      {activeHeaderMenu === "Contributors" && !isUltraLight && (
        <>
          {hasUsers && (
            <>
              {showTable ? (
                <>
                  <EditSelection />
                  <UsersTable
                    hasNextUserPage={hasNextUserPage}
                    isFetchingNextUsersPage={isFetchingNextUsersPage}
                    handleGetNextUserPage={handleGetNextUserPage}
                    userContactMap={flatUserData}
                    isLoadingUsers={isLoadingUsers}
                    showEngagement={showEngagement}
                    hideTemporarilyDependencyStatusNumber={isUltraLight}
                  />
                </>
              ) : (
                flatUserData && (
                  <ListUsers
                    userContactMap={flatUserData}
                    handleGetNextUserPage={handleGetNextUserPage}
                    hasNextUserPage={hasNextUserPage}
                    isFetchingNextUsersPage={isFetchingNextUsersPage}
                  />
                )
              )}
            </>
          )}
        </>
      )}
      {(activeHeaderMenu === "Teams" ||
        activeHeaderMenu === "Contributors") && (
        <NoResults
          searchTerm={
            activeHeaderMenu === "Teams"
              ? repoListParams.searchTerm
              : userListParams.searchTerm
          }
          activeHeaderMenu={activeHeaderMenu}
          hasUsers={hasUsers}
          hasTeams={hasTeams}
          loadedWithLists={loadedWithLists}
          totalNumberOfContactsWaiting={totalNumberOfContactsWaiting}
          loadedWithNoLists={loadedWithNoLists}
        />
      )}
    </>
  );
};

export default ListContactInfoDashboard;
