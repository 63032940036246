import Notification from "~/components/base/Notification/Notification";
import { Constants } from "@openqlabs/utils";
import useNotificationHashCreator from "~/components/base/Notification/useNotificationHashCreator";

export default function ListEvalProgress({
  waitingEvalsCount,
  doneEvalsCount,
}: Readonly<{
  waitingEvalsCount: number;
  doneEvalsCount: number;
}>) {
  const previousResults = doneEvalsCount === 0;
  const percentageDone = doneEvalsCount / (doneEvalsCount + waitingEvalsCount);
  const considerDone = percentageDone > Constants.PERCENTAGE_CONSIDER_DONE;
  const listEvalProgressHash = useNotificationHashCreator({
    waitingEvalsCount,
    doneEvalsCount,
  });
  if (considerDone) return null;
  return (
    <Notification
      id="ListEvalProgress"
      title={"Evaluation in Progress"}
      info={false}
      isLoading={true}
      hash={listEvalProgressHash}
    >
      We are still processing data for this list. Any insights showing below are
      preliminary and based on{" "}
      {previousResults ? (
        "previous evaluations."
      ) : (
        <>
          {doneEvalsCount} / {doneEvalsCount + waitingEvalsCount} evaluations
          performed.
        </>
      )}{" "}
      {/*    */}
    </Notification>
  );
}
